// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCI3-zXZcG4sy1_Qipf9fP35E7lozGAnEQ",
  authDomain: "puzzle-live.firebaseapp.com",
  projectId: "puzzle-live",
  databaseURL: "https://puzzle-live-default-rtdb.asia-southeast1.firebasedatabase.app",
  storageBucket: "puzzle-live.appspot.com",
  messagingSenderId: "1091390282314",
  appId: "1:1091390282314:web:5f526ac52b4d4467fe64b3",
  measurementId: "G-YXPN8EL2WX"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
