<template>
 <!-- Main Sidebar -->
 <a-layout-sider
  collapsible
  class="sider-primary"
  breakpoint="lg"
  collapsed-width="0"
  width="200px"
  :collapsed="sidebarCollapsed"
  @collapse="$emit('toggleSidebar', !sidebarCollapsed)"
  :trigger="null"
  :class="[
   'ant-layout-sider-' + sidebarColor,
   'ant-layout-sider-' + sidebarTheme,
  ]"
  theme="light"
  :style="{ backgroundColor: 'transparent' }"
 >
  <div class="brand">
   <img src="images/favicon-96x96.png" alt />
   <span>관리자</span>
  </div>
  <hr />

  <!-- Sidebar Navigation Menu -->
  <a-menu theme="light" mode="inline">
   <a-menu-item class="menu-item-header">메인</a-menu-item>
   <a-menu-item>
    <router-link to="/dashboard">
     <span class="icon">
      <i class="fa-solid fa-grid-horizontal"></i>
     </span>
     <span class="label">대시보드</span>
    </router-link>
   </a-menu-item>
   <a-menu-item class="menu-item-header">데이타</a-menu-item>
   <a-menu-item>
    <router-link to="/user">
     <span class="icon">
      <i class="fa-solid fa-users"></i>
     </span>
     <span class="label">사용자 현황</span>
    </router-link>
   </a-menu-item>
   <a-menu-item>
    <router-link to="/userreq">
     <span class="icon">
      <i class="fa-solid fa-handshake"></i>
     </span>
     <span class="label">파트너요청 현황</span>
    </router-link>
   </a-menu-item>
   <a-menu-item>
    <router-link to="/ratio">
     <span class="icon">
      <i class="fa-solid fa-handshake"></i>
     </span>
     <span class="label">출판사별 공급가율</span>
    </router-link>
   </a-menu-item>
   <a-menu-item>
    <router-link to="/bookreq">
     <span class="icon">
      <i class="fa-solid fa-bug"></i>
     </span>
     <span class="label">도서 검수요청 현황</span>
    </router-link>
   </a-menu-item>
   <a-menu-item class="menu-item-header">작업</a-menu-item>
   <a-menu-item>
    <router-link to="/hotassign">
     <span class="icon">
      <i class="fa-sharp fa-solid fa-book"></i>
     </span>
     <span class="label">추천 도서 지정</span>
    </router-link>
   </a-menu-item>
   <a-menu-item>
    <router-link to="/coupon">
     <span class="icon">
      <i class="fa-solid fa-ticket"></i>
     </span>
     <span class="label">쿠폰 관리</span>
    </router-link>
   </a-menu-item>
   <a-menu-item>
    <router-link to="/events">
     <span class="icon">
      <i class="fa-solid fa-party-horn"></i>
     </span>
     <span class="label">이벤트 배너 관리</span>
    </router-link>
   </a-menu-item>
   <a-menu-item>
    <router-link to="/point">
     <span class="icon">
     <i class="fa-sharp fa-solid fa-coin-front"></i>
     </span>
     <span class="label">포인트 보정</span>
    </router-link>
   </a-menu-item>
   <a-menu-item>
    <div style="text-align: center; margin-top: 0px">
     <a-button style="width: 100%" @click="onLogOut()">로그아웃</a-button>
    </div>
   </a-menu-item>
  </a-menu>
 </a-layout-sider>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";
const auth = getAuth();
export default {
 props: {
  sidebarCollapsed: {
   type: Boolean,
   default: false,
  },
  sidebarColor: {
   type: String,
   default: "primary",
  },

  sidebarTheme: {
   type: String,
   default: "light",
  },
 },
 data() {
  return {};
 },
 methods: {
  onLogOut() {
   const self = this;
   signOut(auth)
    .then(() => {})
    .catch((error) => {});
  },
 },
};
</script>
<style>
.router-link-active i {
 color: white !important;
}

.layout-dashboard .ant-layout-sider.sider-primary {
 margin: 0 0 0 0 !important;
}
</style>
