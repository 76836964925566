import Vue from 'vue'
import VueRouter from 'vue-router'
import { getAuth, onAuthStateChanged } from "firebase/auth";
Vue.use(VueRouter)

let routes = [
	{
		// will match everything
		path: '*',
		component: () => import('../views/404.vue'),
	},
	{
		path: '/',
		name: 'Home',
		title: '홈',
		redirect: '/dashboard',
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		meta: {
			rule: 'editor',
			title: '대시보드',
			requiresAuth: true
		},
		layout: "dashboard",
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
	},
	{
		path: '/user',
		name: 'User',
		meta: {
			rule: 'editor',
			title: '사용자현황',
			requiresAuth: true
		},
		layout: "dashboard",
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Users.vue'),
	},
	{
		path: '/userreq',
		name: 'userreq',
		meta: {
			rule: 'editor',
			title: '파트너요청현황',
			requiresAuth: true
		},
		layout: "dashboard",
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/UserRequest.vue'),
	},
	{
		path: '/ratio',
		name: 'ratio',
		meta: {
			rule: 'editor',
			title: '출판사별 공급가율',
			requiresAuth: true
		},
		layout: "dashboard",
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Ratio.vue'),
	},
	{
		path: '/bookreq',
		name: 'bookreq',
		meta: {
			rule: 'editor',
			title: '도서검수요청현황',
			requiresAuth: true
		},
		layout: "dashboard",
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/BookRequest.vue'),
	},
	{
		path: '/hotassign',
		name: 'hotassign',
		meta: {
			rule: 'editor',
			title: '추천 도서 지정',
			requiresAuth: true
		},
		layout: "dashboard",
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/HotAssign.vue'),
	},
	{
		path: '/coupon',
		name: 'coupon',
		meta: {
			rule: 'editor',
			title: '쿠폰 관리',
			requiresAuth: true
		},
		layout: "dashboard",
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Coupon.vue'),
	},
	{
		path: '/point',
		name: 'point',
		meta: {
			rule: 'editor',
			title: '포인트 보정',
			requiresAuth: true
		},
		layout: "dashboard",
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Point.vue'),
	},
	{
		path: '/events',
		name: 'events',
		meta: {
			rule: 'editor',
			title: '이벤트 배너 관리',
			requiresAuth: true
		},
		layout: "dashboard",
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Events.vue'),
	},
	// {
	// 	path: '/model',
	// 	name: 'Model',
	// 	meta: {
	// 		rule: 'editor',
	// 		title: '커피기준정보',
	// 		requiresAuth: TextTrackCueList
	// 	},
	// 	layout: "dashboard",
	// 	component: () => import(/* webpackChunkName: "dashboard" */ '../views/Model.vue'),
	// },
	{
		path: '/sign-in',
		name: 'Sign-In',
		component: () => import('../views/Sign-In.vue'),
	}
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
	route.meta = route.meta || {};
	route.meta.layout = route.layout || parentLayout;

	if (route.children) {
		route.children = route.children.map((childRoute) => addLayoutToRoute(childRoute, route.meta.layout));
	}
	return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
})

router.beforeEach((to, from, next) => {
	const auth = getAuth();
	onAuthStateChanged(auth, () => {

		// get firebase current user
		const firebaseCurrentUser = auth.currentUser;

		if (to.path === "/sign-in") {
			if (firebaseCurrentUser) {
				router.push({ path: "/" }).catch(failure => {
					if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
						//ignore;
						console.log('ERROR-ROUTER:Unexpected Router Error', failure)
					}
				})
			}
		}

		// If auth required, check login. If login fails redirect to login page
		if (to.meta.requiresAuth) {
			if (!firebaseCurrentUser) {
				router.push({ path: "/sign-in" }).catch(failure => {
					if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
						//ignore;
						console.log('ERROR-ROUTER:Unexpected Router Error', failure)
					}
				})

			} else {
				return next()
			}
		} else {
			return next()
		}
	})

})

export default router
